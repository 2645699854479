import React, { useState, useEffect, useRef  } from 'react'
import { useTranslation } from 'react-i18next'
import { StaticImage } from "gatsby-plugin-image"

const Chat = props => {

    const { t, i18n } = useTranslation()
    const [isChatOpen, setIsChatOpen] = useState(false); // State to manage dialog visibility
    const [isLoading, setIsLoading] = useState(true);
    const iframeRef = useRef(null);

    // Current language
    const currentLanguage = i18n.language;

    const showChat = () => {
        setIsChatOpen(true); // When the button is clicked, open the chat
        setIsLoading(true); // Show loading indicator when chat is opened
    };

    const closeChat = () => {
        setIsChatOpen(false); // When the close button is clicked, close the chat
    };

    useEffect(() => {
        if (iframeRef.current) {
        const observer = new MutationObserver(() => {
            setIsLoading(false); // Hide loading indicator once iframe content changes
        });

        observer.observe(iframeRef.current, { childList: true, subtree: true });

        return () => {
            observer.disconnect();
        };
        }
    }, [iframeRef.current]);
      
    return (
         <>
            <div className="fixed bottom-0 right-0 mb-8 mr-8 z-50">
                <button onClick={isChatOpen ? closeChat : showChat} className="relative hover:scale-125 hover:drop-shadow-xl">
                    <StaticImage
                        src="../../images/sara.jpg"
                        alt='Chat with us at CTA Electronics'
                        className="w-16 h-16 rounded-full border-2 border-emerald-500"
                        placeholder="blurred"
                        width={100}
                        height={100}
                    />
                    <span className="absolute bottom-0 right-0 block w-4 h-4 bg-emerald-400 rounded-full"></span>
                </button>
            {/* <button onClick={isChatOpen ? closeChat : showChat} className="bg-pink-500 hover:bg-pink-600 text-white font-bold py-2 px-4 rounded-full">
                <span className='flex space-x-2 font-semibold text-white'>
                    <span>Chat</span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 9.75a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 0 1 .778-.332 48.294 48.294 0 0 0 5.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
                    </svg>
                </span>
            </button> */}
            </div>
            {isChatOpen && currentLanguage === 'en' && (
            <dialog open className="border-2 border-slate-100 mb-16 p-0 z-50 drop-shadow-blue rounded-xl" aria-labelledby="chat" aria-describedby="support-chat">
                <div className="bg-emerald-500 rounded-t-lg px-8 py-4">
                    <div className="flex justify-between">
                        <div className="flex items-center space-x-4">
                            <div className="relative">
                                <StaticImage
                                    src="../../images/sara.jpg"
                                    alt='Chat with us at CTA Electronics'
                                    className="w-12 h-12 rounded-full border-2 border-emerald-500"
                                    placeholder="blurred"
                                />
                                <span className="absolute bottom-0 right-0 block w-3 h-3 bg-emerald-400 border border-white rounded-full"></span>
                            </div>
                            <div className="flex flex-col">
                                <p className="font-semibold text-white text-lg">Sara - CTA</p>
                                <p className="text-white text-sm">Available</p>
                            </div>
                        </div>
                        <button onClick={closeChat}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                                className="w-6 text-white">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="flex flex-col">
                    <iframe className="px-4" width="400" height="600" src="https://www.chatsurvey.io/embed/Lagk47t50F" title="en-chat"></iframe>
                </div>
            </dialog>
            )}
            {isChatOpen && currentLanguage === 'it' && (
            <dialog open className="border-2 border-slate-100 mb-16 p-0 z-50 drop-shadow-blue rounded-xl" aria-labelledby="chat" aria-describedby="support-chat">
                <div className="bg-emerald-500 rounded-t-lg px-8 py-4">
                    <div className="flex justify-between">
                        <div className="flex items-center space-x-4">
                            <div className="relative">
                                <StaticImage
                                    src="../../images/sara.jpg"
                                    alt='Chat with us at CTA Electronics'
                                    className="w-12 h-12 rounded-full border-2 border-emerald-500"
                                    placeholder="blurred"
                                />
                                <span className="absolute bottom-0 right-0 block w-3 h-3 bg-emerald-400 border border-white rounded-full"></span>
                            </div>
                            <div className="flex flex-col">
                                <p className="font-semibold text-white text-lg">Sara - CTA</p>
                                <p className="text-white text-sm">Disponibile</p>
                            </div>
                        </div>
                        <button onClick={closeChat}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                                className="w-6 text-white">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="flex flex-col">
                    <iframe className="px-4" width="400" height="600" src="https://www.chatsurvey.io/embed/kzFBh7CTof" title="it-chat"></iframe>
                </div>
            </dialog>
            )}
            {isChatOpen && currentLanguage === 'es' && (
            <dialog open className="border-2 border-slate-100 mb-16 p-0 z-50 drop-shadow-blue rounded-xl" aria-labelledby="chat" aria-describedby="support-chat">
                <div className="bg-emerald-500 rounded-t-lg px-8 py-4">
                    <div className="flex justify-between">
                        <div className="flex items-center space-x-4">
                            <div className="relative">
                                <StaticImage
                                    src="../../images/sara.jpg"
                                    alt='Chat with us at CTA Electronics'
                                    className="w-12 h-12 rounded-full border-2 border-emerald-500"
                                    placeholder="blurred"
                                />
                                <span className="absolute bottom-0 right-0 block w-3 h-3 bg-emerald-400 border border-white rounded-full"></span>
                            </div>
                            <div className="flex flex-col">
                                <p className="font-semibold text-white text-lg">Sara - CTA</p>
                                <p className="text-white text-sm">Disponible</p>
                            </div>
                        </div>
                        <button onClick={closeChat}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                                className="w-6 text-white">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="flex flex-col"> 
                    <iframe className="px-4" width="400" height="600" src="https://www.chatsurvey.io/embed/O4voGdnNfJ" title="es-chat"></iframe>
                </div>
            </dialog>
            )}
        </>
    )
}

export default Chat





