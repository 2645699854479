import React from 'react'
import PropTypes from 'prop-types'

class ModaWithButton extends React.Component {
  state = { showModal: false }

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    })
  }

  render() {
    const modalState = this.state.showModal ? 'is-active' : ''

    return (
      <>
        <div className={`modal ${modalState}`}>
          <div className="modal-background"></div>
          <div className="modal-content">{this.props.component}</div>
          <button
            className={`modal-close is-large`}
            aria-label="close"
            onClick={this.toggleModal}
          ></button>
        </div>
        <button
          className={`button ${this.props.buttonClass}`}
          onClick={this.toggleModal}
        >
          {this.props.buttonLabel}
        </button>
      </>
    )
  }
}

ModaWithButton.propTypes = {
  buttonClass: PropTypes.string,
}

ModaWithButton.defaultProps = {
  buttonClass: 'is-info',
}

export default ModaWithButton
