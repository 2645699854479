import React from 'react'
import { withTranslation } from 'react-i18next'
import Link from '../../utilities/link'
import LanguagePicker from './languagePicker'



class SiteNavigation extends React.Component {
  
  constructor(props){
    super(props);
    this.state = {
      showMenu: false
    }
  }

  toggleMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu,
    })
  };

  

  render() {
    
    const { t } = this.props
    const menuActive = this.state.showMenu ? 'is-active' : ''
    const burgerActive = this.state.showMenu ? 'is-active' : ''
    
    return (
      <header className="navbar is-spaced is-fixed-top">
        <div className="container">
          <div className="navbar-brand">
            <Link
              to={`/`}
              title="CTA Electronics"
              className="navbar-item"
            >
              <strong>CTA ELECTRONICS</strong>
            </Link>

            <span
              className={`navbar-burger burger ${burgerActive}`}
              onClick={this.toggleMenu}
              data-target="navbarMenuHeroC"
            >
              <span></span>
              <span></span>
              <span></span>
            </span>
          </div>
          <div id="navbarMenuHeroC" className={`navbar-menu ${menuActive}`}>
            <div className="navbar-end">
              <div className="navbar-item has-dropdown is-hoverable">
                <p className="navbar-link">{t('header.About')}</p>
                <div className="navbar-dropdown">
                  <div className="columns">
                    <div className="column">
                      <span style={{ paddingLeft: '15px' }}>
                        <strong>{t('header.AboutUs')}</strong>
                      </span>
                      <hr className="navbar-divider"></hr>
                      <Link
                        to={`/chi-siamo`}
                        title={t('header.AboutLinkTitle')}
                        className="navbar-item"
                      >
                        {t('header.About')}
                      </Link>
                      <Link
                        to={`/contatti`}
                        title={t('header.ContactUsLinkTitle')}
                        className="navbar-item"
                      >
                        {t('header.ContactUs')}
                      </Link>
                    </div>
                    <div className="column">
                      <span style={{ paddingLeft: '15px' }}>
                        <strong>{t('header.Jobs')}</strong>
                      </span>
                      <hr className="navbar-divider"></hr>
                      <Link
                        to={`/lavora-con-noi`}
                        title={t('header.CareersLinkTitle')}
                        className="navbar-item"
                      >
                        {t('header.Careers')}
                      </Link>
                      <Link
                        to={`/lavora-con-noi/stage`}
                        title={t('header.StageLinkTitle')}
                        className="navbar-item"
                      >
                        {t('header.Internships')}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="navbar-item has-dropdown is-hoverable">
                <p className="navbar-link">{t('header.Products')}</p>
                <div className="navbar-dropdown" style={{ width: '450px' }}>
                  <div className="columns is-multiline">
                    <div className="column is-6">
                      <span style={{ paddingLeft: '15px' }}>
                        <strong>{t('header.IndustrialControllers')}</strong>
                      </span>
                      <hr className="navbar-divider"></hr>
                      <Link
                        to={`/prodotti/hera-laundry`}
                        title={t('header.HeraLinkTitle')}
                        className="navbar-item"
                      >
                        {t('header.Hera')}
                      </Link>
                      <Link
                        to={`/prodotti/fiber-el6`}
                        title={t('header.EL6LinkTitle')}
                        className="navbar-item"
                      >
                        {t('header.EL6')}
                      </Link>
                      {/* <Link to="/zeni-laundry" className="navbar-item">
                      {t('header.Zeni')}
                    </Link> */}
                    </div>
                    <div className="column is-6">
                      <span style={{ paddingLeft: '15px' }}>
                        <strong>{t('header.BoardsAndModules')}</strong>
                      </span>
                      <hr className="navbar-divider"></hr>
                      {/* <Link to="/controllers" className="navbar-item">
                      {t('header.CPUModules')}
                    </Link> */}
                      <Link
                        to={`/prodotti/schede-rele-interfaccia-plc`}
                        title={t('header.IOLinkTitle')}
                        className="navbar-item"
                      >
                        {t('header.IOBoards')}
                      </Link>
                      <Link
                        to={`/prodotti/sonde-di-temperatura`}
                        title={t('header.SensoriLinkTitle')}
                        className="navbar-item"
                      >
                        {t('header.Sensors')}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="navbar-item has-dropdown is-hoverable">
                <p className="navbar-link">{t('header.Services')}</p>
                <div className="navbar-dropdown">
                  <div className="columns">
                    <div className="column">
                      <span style={{ paddingLeft: '15px' }}>
                        <strong>{t('header.Engineering')}</strong>
                      </span>
                      <hr className="navbar-divider"></hr>
                      <Link
                        to={`/servizi/progettazione-elettronica`}
                        title={t('header.ProgettazioneLinkTitle')}
                        className="navbar-item"
                      >
                        {t('header.ElectronicsDesign')}
                      </Link>
                      <Link
                        to={`/servizi/sviluppo-firmware`}
                        title={t('header.SviluppoFirmwareLinkTitle')}
                        className="navbar-item"
                      >
                        {t('header.FirmwareDevelopment')}
                      </Link>
                      <Link
                        to={`/servizi/sviluppo-software`}
                        title={t('header.SviluppoSoftwareLinkTitle')}
                        className="navbar-item"
                      >
                        {t('header.SoftwareDevelopment')}
                      </Link>
                      <Link
                        to={`/servizi/progettazione-elettronica/nuovo-progetto`}
                        title={t('header.ProgettoLinkTitle')}
                        className="navbar-item"
                      >
                        {t('header.ProjectBoost')}
                      </Link>
                    </div>
                    <div className="column">
                      <span style={{ paddingLeft: '15px' }}>
                        <strong>{t('header.Production')}</strong>
                      </span>
                      <hr className="navbar-divider"></hr>
                      <Link
                        to={`/servizi/produzione-schede-elettroniche`}
                        title={t('header.ProduzioneLinkTitle')}
                        className="navbar-item"
                      >
                        {t('header.ContractManufacturing')}
                      </Link>
                      <Link
                        to={`/servizi/conformal-coating`}
                        title={t('header.CoatingLinkTitle')}
                        className="navbar-item"
                      >
                        {t('header.ConformalCoating')}
                      </Link>
                      <Link
                        to={`/assemblaggio-schede-elettroniche`}
                        title={t('header.AssemblaggioSchedeTitle')}
                        className="navbar-item"
                      >
                        {t('header.AssemblaggioSchede')}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <Link to={`/blog`} className="navbar-item" title="Blog">
                {t('header.Insights')}
              </Link>
              <Link
                to={`/settori`}
                title={t('header.SettoriLinkTitle')}
                className="navbar-item"
              >
                {t('header.Industries')}
              </Link>
              <span className="navbar-item">
                <Link
                  to={`/contatti`}
                  title={t('header.ContactUsLinkTitle')}
                  className="button is-info is-outlined"
                >
                  <span> {t('header.ContactUs')}</span>
                </Link>
              </span>
              <span className="navbar-item">
                <Link
                  to={`/lavora-con-noi`}
                  title={t('header.CareersLinkTitle')}
                  className="button is-primary is-outlined"
                >
                  <span> {t('header.Careers')}</span>
                </Link>
              </span>
              <LanguagePicker />
            </div>
          </div>
        </div>
      </header>
    )
  }
}

export default withTranslation()(SiteNavigation)
