import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { usePageContext } from '../../pageContext'
import { StaticImage } from "gatsby-plugin-image"

const initialValues = {
  email: "",
  name: "",
  last_name: "",
  organization: "",
  privacy: ""
};

const SubscriptionForm = () => {

    const [ formStatus, setFormStatus ] = useState(null);
  
    // Ricaviamo la lingua dal contesto della pagina 
    const { lang } = usePageContext()
    
    // React hook per gestire lo stato dei values
    const [values, setValues] = useState(initialValues);

    // List "Electronics" ID
    const ElectronicsENListID = "eCddV"
    const ElectronicsITListID = "vkkwy"
    const ElectronicsESListID = "VsJAw"

    //Mailercloud API
    const FORM_URL = `https://cloudapi.mailercloud.com/v1/contacts`;

    //Handle input change
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setValues({
        ...values,
        [name]: value,
      });
    };

    const handleSubmit = async (e) => {
        console.log(values)
        e.preventDefault();
        const dataForApi = {
          email: values.email,
          name: values.name,
          last_name: values.last_name,
          organization: values.organization,
          custom_fields: {
            Zuu: values.privacy, //privacy field id
            KaSa: lang === 'it' ? "Italiano" : lang === "en" ? "Inglese" : lang === "es" ? "Spagnolo" : "Inglese", //language field id
            ZuE: new Date().toLocaleString(), //optin date and time
          },
          list_id: lang === 'it' ? ElectronicsITListID : lang === "en" ? ElectronicsENListID : lang === "es" ? ElectronicsESListID : ElectronicsENListID, 
        }
        console.log(dataForApi)
        try {
            const response = await fetch(
                FORM_URL,
                {
                    method: 'post',
                    body: JSON.stringify(dataForApi),
                    headers: {
                        'Authorization': 'bAsfB-ff48b20e62660770134d4d99f6557a7f-bf044791135995e0deea1a8a66445727',
                        'Content-Type': 'application/json',
                    },
                }
            );
            setValues('');
            console.log(response.status)
            if (response.status === 201) {
                setFormStatus('SUCCESS')
                return;
            }
        } catch (err) {
            setValues(setFormStatus('ERROR'))
            console.log(err);
        }
    };

    const { t } = useTranslation();

    return (

    (formStatus === null) ? 
      //Form effettivo

        <div className='md:flex md:items-center md:space-x-20 space-y-8 md:space-y-0'>
          <div className='md:w-1/5'>
            <p className="text-xl font-semibold">{t('subscriberform.title')}</p>
          </div>
          <div className='md:w-4/5'>
            <form
              method="POST"
              name="generalsignup"
              onSubmit={handleSubmit}
            >
              <div className="grid grid-cols-1 md:grid-cols-5 md:gap-4 md:content-end">
                  <div>
                    <label className="font-semibold">
                      {t('projectFormFields.firstName')}
                    </label>
                    <input
                      required
                      className="p-2 w-full drop-shadow-sm rounded border-2 border-green-500"
                      type="text"
                      name="name"
                      onChange={handleInputChange}
                    ></input>
                  </div>
                  <div>
                    <label className="font-semibold">
                      {t('projectFormFields.lastName')}
                    </label>
                    <input
                      required
                      className="p-2 w-full drop-shadow-sm rounded border-2 border-green-500"
                      type="text"
                      name="last_name"
                      onChange={handleInputChange}
                    ></input>
                  </div>
                  <div>
                    <label className="font-semibold">
                      {t('projectFormFields.companyName')}
                    </label>
                    <input
                      required
                      className="p-2 w-full drop-shadow-sm rounded border-2 border-green-500"
                      type="text"
                      name="organization"
                      onChange={handleInputChange}
                    ></input>
                  </div>
                  <div>
                    <label className="font-semibold">
                      {t('projectFormFields.email')}
                    </label>
                    <input
                      required
                      className="p-2 w-full drop-shadow-sm rounded border-2 border-green-500"
                      type="text"
                      name="email"
                      onChange={handleInputChange}
                    ></input>
                  </div>
                  <button
                  type="submit"
                  className="h-10 bg-green-500 rounded-full text-white font-semibold mt-6"
                >
                  {t('projectFormFields.send')}
                </button>
              </div>
                <input
                  required
                  type="checkbox"
                  name="privacy"
                  value="Acconsento al trattamento dei dati come da Privacy Policy"
                  onChange={handleInputChange}
                  className="mt-4"
                />{' '}
                {t('projectFormFields.privacy')}{' '}
                Privacy Policy
            </form>
          </div>
      </div>        
    :
    (formStatus === 'SUCCESS') ? 
      // Messaggio di successo
        <div className='text-center'>
            <p className="text-3xl">
              {t('SignUpFormGeneral.successTitle')}
            </p>
            <p className="pt-4 text-xl">
              {t('SignUpFormGeneral.saluto')},
            </p>
            <p className="pt-4 font-semibold text-xl">CTA Team</p>
        </div>
    :
      // Messaggio di errore
       <div className='text-center'>
          <p className="text-3xl">
            {t('SignUpFormGeneral.errorTitle')}
          </p>
          <p className="pt-4 text-xl">
            {t('SignUpFormGeneral.saluto')},
          </p>
          <p className="pt-4 font-semibold text-xl">CTA Team</p>
        </div>
      )
}

export default SubscriptionForm
